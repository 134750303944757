// margin and padding values array
$space-values : (
    0: 0,
    1: 0.25,
    2: 0.5,
    3: 0.75,
    4: 1,
    5: 1.25,
    6: 1.5,
    7: 1.75,
    8: 2,
    9: 2.25,
    10: 2.5,
    11: 2.75,
    12: 3,
    13: 3.25,
    14: 3.5,
    15: 3.75,
    16: 4,
) !default;

// margin and padding shorthands
$space-prefixes : (
  p  : padding,
  pt : padding-top,
  pr : padding-right,
  pb : padding-bottom,
  pl : padding-left,
  m  : margin,
  mt : margin-top,
  mr : margin-right,
  mb : margin-bottom,
  ml : margin-left,
) !default;

@mixin make-spaces() {
  @each $attr-short, $attr-long in $space-prefixes {
    @each $key, $value in $space-values {
      .#{$attr-short}-#{$key} {
        #{$attr-long}: #{$value}#{'rem'};
      }
    }
  }
  @each $key, $value in $space-values {
    .px-#{$key} {
      padding-left: #{$value}#{'rem'};
      padding-right: #{$value}#{'rem'};
    }
    .mx-#{$key} {
      margin-left: #{$value}#{'rem'};
      margin-right: #{$value}#{'rem'};
    }
    .py-#{$key} {
      padding-top: #{$value}#{'rem'};
      padding-bottom: #{$value}#{'rem'};
    }
    .my-#{$key} {
      margin-top: #{$value}#{'rem'};
      margin-bottom: #{$value}#{'rem'};
    }
  }
}

@include make-spaces();
