@import '../../css/global/colors.scss';

.DayPicker-wrapper:focus,
.DayPicker-Day:focus,
.DayPicker-NavButton {
  outline: none;
}
.DayPicker-Day {
  border-radius: 4px;
}
.DayPicker-Day:hover {
  background-color: $lighterTeal !important;
}
.DayPicker-Day.DayPicker-Day--today {
  color: $darkTeal;
}
.DayPicker-Day.DayPicker-Day--disabled {
  color: $grey;
  &:hover {
    background: none !important;
  }
}
.DayPicker-Day.DayPicker-Day--selected {
  color: $white !important;
  background-color: $darkerTeal !important;
}
.DayPicker-Day--outside {
  &:hover {
    background: none !important;
  }
}
.rdp-button:hover {
  background-color: $lighterTeal !important;
  border-radius: 4px;
}
.rdp-day_today {
  color: $darkTeal;
}
.rdp-day_selected {
  background-color: $darkerTeal !important;
  border-radius: 4px;
}