@import 'reset.scss';
@import 'global/index.scss';
@import 'helpers/index.scss';

body, html {
  font-family: $fontFamily;
  font-size: $fontDefault;
  -webkit-font-smoothing: antialiased;
}

a {
  color: var(--darkerTeal);
  &:hover {
    color: var(--darkTeal);
  }
}

.grecaptcha-badge {
  display: none !important;
}

:root {
  // colors
  --lighterTeal: #{$lighterTeal};
  --lightTeal: #{$lightTeal};
  --normalTeal: #{$normalTeal};
  --darkTeal: #{$darkTeal};
  --darkerTeal: #{$darkerTeal};
  --whiteGrey: #{$whiteGrey};
  --lightGrey: #{$lightGrey};
  --blueGrey: #{$blueGrey};
  --grey: #{$grey};
  --darkGrey: #{$darkGrey};
  --darkerGrey: #{$darkerGrey};
  --white: #{$white};
  --hintOfGreen: #{$hintOfGreen};
  --warmPink: #{$warmPink};
  --warmPinkRgb: #{$warmPinkRgb};
  --black: #{$black};
  --lighterRed: #{$lighterRed};
  --red: #{$red};
  --yellow: #{$yellow};
  --green: #{$green};

  // border radius
  --smallerBorderRadius: #{$smallerBorderRadius};
  --smallBorderRadius: #{$smallBorderRadius};
  --mediumBorderRadius: #{$mediumBorderRadius};
  --largeBorderRadius: #{$largeBorderRadius};

  // typography
  --fontFamily: #{$fontFamily};
  --fontNormal: #{$normal};
  --fontSemibold: #{$semibold};
  --fontBold: #{$bold};
}
