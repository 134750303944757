@import '../global/index.scss';

$colors : (
lighterTeal: $lighterTeal,
lightTeal: $lightTeal,
normalTeal: $normalTeal,
darkTeal: $darkTeal,
darkerTeal: $darkerTeal,
green: $green,
whiteGrey: $whiteGrey,
lightGrey:$lightGrey,
blueGrey: $blueGrey,
grey: $grey,
darkGrey: $darkGrey,
darkerGrey: $darkerGrey,
white: $white,
hintOfGreen: $hintOfGreen,
warmPink: $warmPink,
warmPinkRgb: $warmPinkRgb,
black: $black,
lighterRed: $lighterRed,
red: $red,
yellow: $yellow,
) !default;

@mixin make-color-utils() {
  @each $color, $value in $colors {
    .bg-#{"" + $color} {
      background-color: $value !important;
    }
    .text-#{"" + $color} {
      color: $value !important;
    }
  }
}

@include make-color-utils();