@import '../mixins/index.scss';

.container {
  width: 100%;
  padding: 0 24px;
  box-sizing: border-box;
  margin: 0 auto;

  @include above(xs) {
    max-width: $sm;
  }

  @include above(sm) {
    max-width: $md;
  }

  @include above(md) {
    max-width: $lg;
  }

  @include above(lg) {
    padding: 0;
  }
  &.fluid-mobile {
    @include below(sm) {
      width: 100%;
      padding: 0 !important;
    }
  }
}