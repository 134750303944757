@import '../../css/global/colors';

.wrapper {
  display: flex;
  &.center {
    flex: 1;
    height: 100%;
    justify-content: center;
    align-items: center;
  }
  &.screen-center {
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 99;
  }
  &.overlay {
    background: rgba(0, 0, 0, .5);
  }
  .spinner {
    position: relative;
    &:after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      border-style: solid;
      border-color: $lighterTeal;
      border-top-color: $darkTeal;
      border-radius: 50%;
      animation: spin 1s ease infinite;
    }

    &.small {
      width: 1rem;
      height: 1rem;
      &:after {
        border-width: 0.2rem;
      }
    }
    &.medium {
      width: 4rem;
      height: 4rem;
      &:after {
        border-width: 0.8rem;
      }
    }
    &.large {
      width: 6rem;
      height: 6rem;
      &:after {
        border-width: 1rem;
      }
    }
  }
}

@keyframes spin {
  to {
    transform: rotate(1turn);
  }
}