body, html {
  margin: 0;
  padding: 0;
}

h1, h2, h3, h4, h5, p, a {
  margin: 0;
  padding: 0;
}

button {
  border: 0;
  background: none;
  margin: 0;
  padding: 0;
  &:focus {
    outline: none;
  }
  &:hover {
    cursor: pointer;
  }
}