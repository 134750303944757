@import '../../css/global/colors.scss';
@import '../../css/global/typography.scss';

.label {
  position: relative;
  display: flex;
  .customCheckbox {
    position: relative;
    width: 24px;
    min-width: 24px;
    height: 24px;
    .checkbox {
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      z-index: 3;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      border-radius: 2px;
      border: 2px solid $black;
      background-color: transparent;
      .checkMark {
        font-weight: 400;
        font-size: 20px;
        color: $lighterTeal;
      }
    }
    .ripple,
    .hoverBackground {
      position: absolute;
      border-radius: 50%;
      opacity: 0;
    }
    .ripple {
      z-index: 2;
      top: -16px;
      bottom: -16px;
      left: -16px;
      right: -16px;
      background-color: $darkerTeal;
      animation: ripple 0.2s ease-in;
    }
    .hoverBackground {
      z-index: 1;
      top: -8px;
      bottom: -8px;
      left: -8px;
      right: -8px;
    }
    &:hover,
    &.focused {
      cursor: pointer;
      .hoverBackground {
        opacity: 1;
        background-color: $lighterTeal;
      }
      .checkbox {
        background-color: $lighterTeal;
      }
    }
    &.checked {
      .checkbox {
        background-color: $darkerTeal;
        border-color: $darkerTeal;
      }
      &:hover,
      &.focused {
        .checkbox {
          background-color: $darkerTeal;
          border-color: $darkerTeal;
        }
      }
    }
    &.error {
      .hoverBackground {
        background-color: $lighterRed !important;
      }
      .checkbox {
        border-color: $red !important;
      }
    }
    &.disabled {
      cursor: default;
      .checkbox {
        border-color: $grey;
      }
      &:hover {
        .hoverBackground {
          opacity: 0;
        }
        .checkbox {
          background-color: transparent;
        }
      }
      &.checked {
        .checkbox {
          background-color: $grey;
          border-color: $grey;
        }
      }
    }
  }
  .checkboxInput {
    -webkit-appearance: none;
    position: absolute;
    left: 0;
    margin: 0;
    padding: 0;
    opacity: 0;
    height: 0;
    width: 0;
  }
  .labelText {
    margin: 0 0 0 1rem !important;
    font-size: $fontMd;
    color: $black;
    line-height: 1.5rem;
    &.empty {
      display: none;
    }
    a {
      color: $darkerTeal;
      text-decoration: underline;
    }
  }
  &.white {
    .labelText {
      color: $white
    }
    .customCheckbox {
      .checkbox {
        border-color: $white;
        border-width: 1px;
      }
      .hoverBackground {
        background: none;
      }
      &.checked {
        .checkbox {
          background-color: $white !important;
          .checkMark {
            color: $darkerTeal;
            left: 1px;
            top: 2px;
          }
        }
      }
      &:hover {
        background: none;
        .hoverBackground {
          display: none;
        }
        .checkbox {
          border-color: $white;
          background: none;
        }
      }
    }
  }
}
.errorMessage {
  margin-top: 12px;
}

@keyframes ripple {
  0% {
    opacity: 1;
    transform: scale(0, 0);
  }
  50% {
    transform: scale(.6, .6);
  }
  100% {
    opacity: 0;
    transform: scale(1, 1);
  }
}