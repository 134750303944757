@import '../../css/global/colors.scss';

.select {
  color: $grey;
  &.withValue {
    color: $black;
  }
  &.hidden {
    opacity: 0;
  }
}
.styledSelect {
  z-index: 4 !important;
  &.notTypeable {
    user-select: none;
    cursor: pointer;
    caret-color: transparent;
  }
  &.disabled {
    cursor: default !important;
  }
  &.isMultiSelect {
    position: absolute !important;
  }
}
.multiOptionsWrapper {
  display: flex;
  flex-wrap: wrap;
  z-index: 5;
  padding-left: 15px;
  padding-right: 43px;
  box-sizing: border-box;
  gap: 0.5rem;
  margin: 0.5rem 0;
  .multiOption {
    display: flex;
    background: $lighterTeal;
    padding: 0.125rem 0.5rem 0.125rem 1rem;
    border-radius: 3px;
    margin: 0 0.25rem 0 0;
    line-height: 1.2;
    .deleteButton {
      margin-left: 1rem;
    }
  }
  &.underline {
    padding-left: 0;
    margin: 0.75rem 0 0.5rem;
    padding-right: 20px;
  }
  &.small {
    .multiOption {
      padding: 0 0.5rem 0 1rem;
    }
  }
}

.option {
  padding: 15px;
  cursor: pointer;
  line-height: 1.4;
  &.highlighted {
    background-color: $lighterTeal;
  }
  &.selected {
    background-color: $darkTeal;
    color: $white;
    cursor: default;
    * {
      color: $white;
    }
  }
  &.enableUserSelect {
    cursor: pointer;
  }
}
.dropdown {
  position: absolute;
  box-sizing: border-box;
  border-radius: 4px;
  border: 1px solid $darkTeal;
  overflow: auto;
  background-color: $white;
  z-index: 999999;
  &.empty {
    padding: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.nativeSelect {
  position: absolute !important;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 5 !important;
  &.disabled {
    cursor: default !important;
  }
}
.spinnerWrapper {
  height: 100px;
}