@import '@frontend-components/css/global/colors.scss';

.rdp-day_disabled {
  background-color: $blueGrey;
  cursor: default !important;
}

.rdp-day {
  border-radius: 4px;
}

.rdp-button:not([disabled]) {
  opacity: 0.7;
}

.rdp-day_today {
  color: inherit;
  font-weight: normal !important;
}

.rdp-day_selected {
  color: $white !important;
}

.rdp-cell {
  padding-right: 5px;
}

.redDayButton {
  background-color: $red;
}

.yellowDayButton {
  background-color: $yellow;
}

.greenDayButton {
  background-color: $green;
}